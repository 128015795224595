  body {
    height: auto;
    background-color: #f5f5f5;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  header{
    background-color:#68a7de;
    color:white;
  }
  .card-header{
    background-color: #828282;
    color: white;
  }
  header ul li a{
    color:white;
  }
  .content-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }