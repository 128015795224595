section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  vertical-align: 5vh   ;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ams{
    padding-top: 30px;
    font-size: 80px;
    font-weight: bolder;
    
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: -webkit-linear-gradient(#034d7a, #97d2f7);
    -webkit-background-clip: text;
     -webkit-text-fill-color: transparent; 
     text-shadow: 2px 0 0 #fff,
    
}